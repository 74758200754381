import React, { useState } from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import Alert from '../../components/Alert/Alert';
import SEO from '../../components/seo';
import LinkList from '../../components/LinkList/LinkList';
import PaginationNav from '../../components/PaginationNav/PaginationNav';

import { getPaginatedResults } from '../../utils/sorting';

const NewsReleasesPage = ({ data }) => {
	const intl = useIntl();
	const [currentPage, setPage] = useState(1);

	const newsArr = data.markdownArticles.edges.map((link) => ({
		path: `/news-and-events/news-releases/${link.node.frontmatter.title.toLowerCase().replace(/\s+/g, '-')}/`,
		text: link.node.frontmatter.title,
		subtitle: link.node.frontmatter.subtitle,
		date: moment(link.node.frontmatter.date).format('MMMM Do, YYYY'),
		internal: true
	}));

	return (
		<Layout
			header={
				<span className='bold'>
					<FormattedMessage id='nav.newsReleases' />
				</span>
			}
			breadcrumb={{
				text: <FormattedMessage id='nav.news' />,
				path: '/news-and-events/'
			}}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'nav.newsReleases' })} />
			<Container>
				<Row>
					<Col>
						{intl.locale === 'fr' && (
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						)}

						<LinkList links={getPaginatedResults(newsArr, currentPage)} />

						{newsArr.length > 10 && (
							<PaginationNav
								currentPage={currentPage}
								clickEvt={setPage}
								resultsLength={newsArr.length}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default NewsReleasesPage;

export const pageQuery = graphql`
	query NewsQuery {
		markdownArticles: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/newsevents/" } }
			sort: { order: DESC, fields: frontmatter___date }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						subtitle
						date
						image {
							absolutePath
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`;
